const TOKEN_KEY = 'token';

export const saveToken = (token: string): void => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const getToken = (): string | null => {
  return localStorage.getItem(TOKEN_KEY);
};

export const removeToken = (): void => {
  localStorage.removeItem(TOKEN_KEY);
};

export const makeAuthenticatedRequest = async (url: string, options: RequestInit): Promise<Response> => {
  const token = getToken();
  if (!token) {
    throw new Error('No token found');
  }

  if (!options.headers) {
    options.headers = {};
  }

  (options.headers as any)['Authorization'] = `Bearer ${token}`;

  return fetch(url, options);
}