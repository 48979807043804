import React from 'react';
import Button from './Button'; // Import the Button component

interface PrivacyPolicyPopupProps {
  isOpen: boolean;
  closePopup: () => void;
}

const PrivacyPolicyPopup: React.FC<PrivacyPolicyPopupProps> = ({ isOpen, closePopup }) => {
  if (!isOpen) return null; // Don't render if not open

  return (
    <>
      {/* Dim the background and show the popup */}
      {isOpen && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-[#f0f8ff] w-4/5 md:w-3/5 lg:w-2/5 p-6 rounded-lg shadow-lg relative overflow-y-auto max-h-[80vh]">
          <div className="p-4 bg-white rounded text-justify text-sm border">
              <h1 className="font-bold text-[#00008b] text-xl text-center mb-12">Informativa sulla Privacy</h1>

              <p className="mt-4 leading-relaxed">
                La presente informativa sulla privacy è redatta in conformità al Regolamento (UE) 2016/679 (&quot;GDPR&quot;) e descrive come raccogliamo, utilizziamo, proteggiamo e gestiamo i dati personali dei nostri utenti.
              </p>

              <h2 className="font-bold text-[#00008b] text-lg mt-6">
                <span className="text-[#ffa500]">1.</span> Titolare del Trattamento
              </h2>
              <p className="mt-2 leading-relaxed">
                Il titolare del trattamento dei dati personali è Italian Coffee & Crafts LLC, con sede legale in Shaban Polluzha, 15, Prishtina, XK, contattabile all&apos; indirizzo email: {process.env.NEXT_PUBLIC_SUPPORT_EMAIL}.
              </p>

              <h2 className="font-bold text-[#00008b] text-lg mt-6">
                <span className="text-[#ffa500]">2.</span> Tipologie di Dati Raccolti
              </h2>
              <p className="mt-2 leading-relaxed">Durante l&apos; utilizzo dei nostri servizi, possiamo raccogliere le seguenti categorie di dati:</p>
              <ul className="list-disc list-inside ml-4 mt-2 leading-relaxed">
                <li>Dati personali: informazioni identificative come nome, indirizzo email, numero di telefono.</li>
                <li>Dati di transazione: informazioni sulle operazioni effettuate dall&apos; utente (ad esempio, importi, date delle transazioni, etc.), i quali verranno trattati in forma anonima.</li>
              </ul>

              <h2 className="font-bold text-[#00008b] text-lg mt-6">
                <span className="text-[#ffa500]">3.</span> Finalità del Trattamento
              </h2>
              <p className="mt-2 leading-relaxed">I dati personali saranno trattati per le seguenti finalità:</p>
              <ul className="list-disc list-inside ml-4 mt-2 leading-relaxed">
                <li>Gestione degli account utente e fornitura dei servizi richiesti.</li>
                <li>Miglioramento dei nostri servizi attraverso l&apos; analisi anonima dei dati di transazione.</li>
                <li>Comunicazioni relative ai servizi forniti.</li>
              </ul>

              <h2 className="font-bold text-[#00008b] text-lg mt-6">
                <span className="text-[#ffa500]">4.</span> Trattamento dei Dati
              </h2>
              <p className="mt-2 leading-relaxed">
                Tutti i dati degli utenti vengono conservati su server situati all&apos; interno dell&apos; Unione Europea. 
                I dati di transazione dell&apos; utente vengono trattati in modo separato rispetto ai dati personali. Tali dati saranno anonimizzati e crittografati per garantire massima sicurezza e tutela.
                Il nostro personale autorizzato potrà accedere ai dati di transazione, esclusivamente in forma anonima, al fine di migliorare la qualità dei servizi offerti.
              </p>

              <h2 className="font-bold text-[#00008b] text-lg mt-6">
                <span className="text-[#ffa500]">5.</span> Accesso ai Dati da Parte dei Dipendenti
              </h2>
              <p className="mt-2 leading-relaxed">
                I nostri dipendenti potranno visualizzare i dati relativi alle transazioni esclusivamente per scopi di ottimizzazione del servizio, come la risoluzione di problemi tecnici e il miglioramento delle funzionalità della piattaforma. I dati personali che potrebbero permettere l&apos; identificazione diretta dell&apos; utente non saranno condivisi né accessibili ai dipendenti durante queste attività.
              </p>

              <h2 className="font-bold text-[#00008b] text-lg mt-6">
                <span className="text-[#ffa500]">6.</span> Conservazione dei Dati
              </h2>
              <p className="mt-2 leading-relaxed">
                I dati personali e i dati di transazione saranno conservati per il tempo strettamente necessario alle finalità sopra indicate o per il periodo previsto dalla legge.
              </p>

              <h2 className="font-bold text-[#00008b] text-lg mt-6">
                <span className="text-[#ffa500]">7.</span> Misure di Sicurezza
              </h2>
              <p className="mt-2 leading-relaxed">
                Adottiamo misure tecniche e organizzative adeguate per proteggere i dati personali e i dati di transazione da accessi non autorizzati, perdita, divulgazione o distruzione. La separazione dei dati personali dai dati di transazione e la crittografia dei dati sono parte integrante della nostra politica di sicurezza.
              </p>

              <h2 className="font-bold text-[#00008b] text-lg mt-6">
                <span className="text-[#ffa500]">8.</span> Divulgazione a Terze Parti
              </h2>
              <p className="mt-2 leading-relaxed">
                I dati personali e i dati di transazione degli utenti non saranno condivisi con terze parti, a meno che non sia richiesto per legge o in caso di espressa autorizzazione dell&apos; utente.
              </p>

              <h2 className="font-bold text-[#00008b] text-lg mt-6">
                <span className="text-[#ffa500]">9.</span> Consenso Esplicito per Operazioni non Essenziali
              </h2>
              <p className="mt-2 leading-relaxed">Alcune operazioni di trattamento dei dati richiedono il consenso esplicito e separato degli utenti, che potrà essere revocato in qualsiasi momento senza compromettere l&apos; uso del servizio principale. Queste operazioni includono:</p>
              <ul className="list-disc list-inside ml-4 mt-2 leading-relaxed">
                <li><strong>Invio di Comunicazioni Promozionali:</strong> Inviare offerte promozionali, sondaggi e informazioni su prodotti o servizi che riteniamo possano essere di interesse dell&apos; utente. Questo sarà fatto solo previo consenso.</li>
                <li><strong>Condivisione dei Dati con Terze Parti:</strong> Condivisione dei dati con partner commerciali per finalità di marketing o pubblicitarie. Gli utenti potranno scegliere se consentire questa condivisione.</li>
                <li><strong>Analisi Avanzata e Personalizzazione:</strong> Utilizzo dei dati per analisi avanzate, miglioramento dei servizi e personalizzazione dell’esperienza utente (come raccomandazioni personalizzate). Questo trattamento richiederà il consenso esplicito dell&apos; utente.</li>
              </ul>
              <p className="mt-2 leading-relaxed">Tutte le altre operazioni necessarie per il funzionamento del servizio, quali la gestione degli account e l&apos; elaborazione delle transazioni, sono considerate essenziali e implicano il consenso dell&apos; utente attraverso l&apos; accettazione dei Termini e Condizioni del servizio.</p>

              <h2 className="font-bold text-[#00008b] text-lg mt-6">
                <span className="text-[#ffa500]">10.</span> Diritti dell&apos; Interessato
              </h2>
              <p className="mt-2 leading-relaxed">Gli utenti hanno il diritto di:</p>
              <ul className="list-disc list-inside ml-4 mt-2 leading-relaxed">
                <li>Richiedere l&apos; accesso ai propri dati personali.</li>
                <li>Chiedere la rettifica o la cancellazione dei dati.</li>
                <li>Limitare il trattamento dei dati o opporsi ad esso.</li>
                <li>Richiedere la portabilità dei dati.</li>
              </ul>
              <p className="mt-2 leading-relaxed">
                Per esercitare tali diritti, l&apos; utente può contattarci all&apos; indirizzo email {process.env.NEXT_PUBLIC_SUPPORT_EMAIL}, oppure scrivendoci dalla sezione &quot;Assistenza&quot;, una volta effettuato il login.
              </p>

              <h2 className="font-bold text-[#00008b] text-lg mt-6">
                <span className="text-[#ffa500]">11.</span> Modifiche alla Privacy Policy
              </h2>
              <p className="mt-2 leading-relaxed">
                Ci riserviamo il diritto di aggiornare la presente informativa sulla privacy in qualsiasi momento. Le modifiche saranno comunicate agli utenti attraverso il nostro sito web o altri canali di comunicazione.
              </p>

              <p className="mt-4"><strong>Ultimo aggiornamento:</strong> 25/09/2024</p>
            </div>

            {/* Close button at the bottom center */}
            <div className="flex justify-center mt-6">
              <Button variant="blue" onClick={closePopup} className="text-xl py-2 px-5">
                Chiudi
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PrivacyPolicyPopup;
