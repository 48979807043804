import React from 'react';
import classNames from 'classnames';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'orange' | 'hero' | 'transparent' | 'transparent-orange' | 'grey' | 'blue' | 'red-icon' | 'grey-icon' | 'blue-icon' | 'dotted';
  className?: string;
  onClick?: () => void;
  buttonKey?: string | number;
  children: React.ReactNode;
  type?: 'button' | 'submit' | 'reset';
}

const Button: React.FC<ButtonProps> = ({ variant = 'orange', className, onClick, buttonKey, children, type = 'button', ...rest }) => {

  const buttonClass = classNames(
    'font-bold m-2 transition-all duration-200 rounded-full flex items-center justify-center',
    {
      'bg-secondary px-4 py-3 text-primary hover:bg-primary hover:text-white': variant === 'orange',
      'bg-transparent px-4 py-2 border-2 border-primary text-primary hover:bg-primary hover:text-white': variant === 'transparent',
      'bg-transparent px-4 py-2 border-2 border-primary text-primary hover:bg-secondary hover:border-transparent': variant === 'transparent-orange',
      'bg-gray px-4 text-white py-3 bg-opacity-35 hover:bg-opacity-100': variant === 'grey',
      'bg-primary px-4 p-1 text-white hover:bg-secondary hover:text-primary': variant === 'blue',
      'bg-primary p-3 text-white hover:bg-secondary hover:text-primary': variant === 'blue-icon',
      'bg-red-500 p-3 text-white hover:bg-red-700': variant === 'red-icon',
      'bg-secondary px-8 py-3 md:py-4 text-base md:text-2xl font-bold text-primary hover:bg-white hover:text-primary hover:opacity-100 hover:scale-105 m-0 ml-[-4px] font-sans': variant === 'hero',
      'bg-gray p-3 text-white py-3 bg-opacity-35 hover:bg-opacity-60': variant === 'grey-icon',
      'bg-transparent px-5 py-2 border-4 border-black border-dashed text-dark-blue hover:bg-primary hover:text-white hover:border-transparent': variant === 'dotted',
    },
    className
  );

  return (
    <button className={buttonClass} onClick={onClick} key={buttonKey} type={type} {...rest}>
      {children}
    </button>
  );
};

export default Button;
